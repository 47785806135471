import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { action, observable, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import ScrollBooster from 'scrollbooster';
import { useAppContext } from '../../controllers/app.controller';
import { isBuildTime } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { ImageDataLike } from '../../types/gatsby.types';
import { makeDisposerController } from '../../utils/disposer.utils';
import { getScrollY } from '../../utils/dom.utils';
import { useStore } from '../../utils/mobx.utils';
import { registerParallaxEffect } from '../../utils/parallax.utils';
import './AppScreenshotSlider.scss';

export type AppScreenshotSlide = {
  image: ImageDataLike,
  title?: string,
  alt?: string,
}
type AppScreenshotSliderProps = {
  slides: AppScreenshotSlide[],
}

const AppScreenSlide: React.FC<{ slide: AppScreenshotSlide }> = props => {
  const image = getImage(props.slide.image);
  return <Observer children={() => (
    <figure className="AppScreenshotSlide">
      { image && <GatsbyImage image={image} title={props.slide.title} alt={props.slide.alt ?? props.slide.title ?? ''} /> }
    </figure>
  )} />
}

/**
 * A slider that shows a list of app screenshots. 
 * Those screenshots must be exactly equal in size.
 * The slider will move horizontally as user scrolls the page, 
 * and remains scrollable (swipable too) should the user wish to view the content
 */
const AppScreenshotSlider: React.FC<AppScreenshotSliderProps> = props => {
  const ref = useObservableRef();
  const innerRef = useObservableRef();
  const { UI } = useAppContext();
  const s = useStore(() => ({
    innerWidth: '100%' as string | number,
    get innerStyle() {
      return {
        width: s.innerWidth,
      }
    }
  }))
  useOnMount(() => {
    if (isBuildTime) return;
    const d = makeDisposerController();
    let prevScrollPosition = getScrollY();
    d.add(registerParallaxEffect(ref, observable({ 
      id: 'AppScreenshotSliderInner', 
      customFunction: () => {
        const { scrollY } = UI.viewport;
        const delta = scrollY - prevScrollPosition;
        ref.current?.scrollBy({ left: delta / 3 });
        prevScrollPosition = scrollY;
      }
    })));
    d.add(
      when(
        () => UI.viewport.width >= 768 || UI.ui.pointerTypes.includes('mouse'),
        () => {
          new ScrollBooster({
            viewport: ref.current,
            direction: 'horizontal',
            scrollMode: 'native',
          })
        }
      )
    )
    const handleResize = action(() => {
      s.innerWidth = innerRef.current?.scrollWidth ?? 'max-content';
    })
    window.addEventListener('resize', handleResize);
    handleResize();
    d.add(() => window.removeEventListener('resize', handleResize));
    return d.disposer;
  });
  return <Observer children={() => (
    <div className="AppScreenshotSlider" ref={ref}>
      <div className="AppScreenshotSliderInner" ref={innerRef} style={s.innerStyle}>
        { props.slides.map(slide => <AppScreenSlide slide={slide} key={slide.title} />) }
      </div>
    </div>
  )} />
}

export default AppScreenshotSlider;